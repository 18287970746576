import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        textAlign: "center",

        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
        padding: "10px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 20,
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        {" "}
        {/* Increased gap */}
        <a
          href="mailto:siabuttan23@gmail.com"
          style={{ color: "grey", textDecoration: "none" }}
        >
          <i
            className="fas fa-envelope"
            style={{ color: "grey !important" }}
          ></i>
        </a>
        <a
          href="https://www.linkedin.com/in/sia-buttan-190a682a6/"
          style={{ color: "grey", textDecoration: "none" }}
        >
          <i
            className="fab fa-linkedin"
            style={{ color: "grey !important" }}
          ></i>
        </a>
        <a
          href="https://www.instagram.com/sia_buttan/"
          style={{ color: "grey", textDecoration: "none" }}
        >
          <i
            className="fab fa-instagram"
            style={{ color: "grey !important" }}
          ></i>
        </a>
      </div>
      <p style={{ marginTop: "10px", color: "grey" }}>
        Copyright 2024 © Sia Buttan. All rights reserved
      </p>
    </footer>
  );
};

export default Footer;
