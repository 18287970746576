import "./AboutContact.css"; // Make sure to create a corresponding CSS file
import profileImage from "./sia_crop.jpg"; // Assuming sia.jpg is in the same directory as this file
import React, { useRef } from "react";
import pdfFile from "./sia_buttan_artist_resume.pdf";

function AboutContact() {
  function downloadPdfDocument() {
    const link = document.createElement("a");
    // Use the S3 URL directly
    link.href =
      "https://sia-website.s3.amazonaws.com/resume/sia_buttan_artist_resume.pdf";
    link.download = "sia_buttan_artist_resume.pdf";
    link.click();
  }

  return (
    <div className="about-contact-container">
      <div className="left-column">
        <h1>Sia Buttan</h1>
        <h6>ARTIST</h6>
        <h3>Profile</h3>
        <p>
          I am a final year BFA student at ArtCenter College of Design,
          Pasadena, Los Angeles. The past three years at ArtCenter have given me
          the opportunity to hone my skills with some of the best and most
          experienced people in my industry. With thousands of hours of practice
          in Visual Development, Storyboarding, Animation, and Figure Drawing, I
          feel ready to make your ideas come to life.
        </p>
        <p>
          I am excited to work in a team on the next big Feature film,
          Animation, Video game, Installation Art, or any other creative medium
          where I can express myself and become a valuable part of a talented
          team.
        </p>
        <h3>Employment History</h3>
        <div>
          <h4>Teachers Assistant, Edgar Silva, Pasadena</h4>
          <p>SEPTEMBER 2023 — DECEMBER 2023</p>
          <ul>
            <li>Figure drawing class at ArtCenter - Heads and Hands.</li>
            <li>
              Provided one on one help to students struggling to understand
              anatomy.
            </li>
            <li>
              Corrected projects and helped provide feedback on areas for
              improvement.
            </li>
          </ul>
        </div>
        <div>
          <h4>Teachers Assistant, Jeannie Winston Nogai, Pasadena</h4>
          <p>JANUARY 2024 — APRIL 2024</p>
          <ul>
            <li>
              Sketching for Entertainment class at ArtCenter Visual Development
              class. Helped students develop a deeper understanding for both
              traditional and digital drawing techniques.
            </li>
            <li>
              Monitored and reported student progress to the teacher, providing
              feedback on areas of improvement.
            </li>
          </ul>
        </div>
        <h3>Education</h3>
        <h5>Bachelors of Fine Arts, ArtCenter College of Design, Pasadena</h5>
        <p>JANUARY 2022 — APRIL 2025</p>
        <p>Major in Illustration- Entertainment Arts</p>
        <p>Minor in Creative Writing</p>
        <button className="download-pdf-button" onClick={downloadPdfDocument}>
          Download PDF
        </button>
      </div>
      <div className="right-column">
        <img src={profileImage} alt="Sia Buttan" />
        <h3>Current Location</h3>
        <ul>
          <li>Pasadena, Los Angeles</li>
          <li>United States</li>
          <li>
            <a href="mailto:siabuttan23@gmail.com" style={{ color: "white" }}>
              siabuttan23@gmail.com
            </a>
          </li>
        </ul>

        <h3>NATIONALITY</h3>
        <ul>
          <li>Indian</li>
        </ul>
        <h3>Links</h3>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/sia_buttan/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white" }}
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/sia-buttan-190a682a6/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white" }}
            >
              LinkedIn
            </a>
          </li>
        </ul>
        <h3>Skills</h3>
        <ul>
          <li>Visual Development</li>
          <li>Storyboarding</li>
          <li>Illustration</li>
          <li>Character Design</li>
          <li>Art Direction</li>
        </ul>
        <h3>Languages</h3>
        <ul>
          <li>English</li>
          <li>Hindi</li>
          <li>Marathi</li>
        </ul>
      </div>
    </div>
  );
}

export default AboutContact;
