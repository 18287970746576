import React from "react";
import { NavLink } from "react-router-dom"; // Import NavLink instead of Link
import "./Header.css"; // Make sure your CSS file is imported

const Header = () => {
  return (
    <header>
      <h1>SIA BUTTAN</h1>
      <p>Art Direction · Character Design · Visual Development</p>
      <nav>
        <NavLink
          to="/"
          end
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          Home
        </NavLink>
        <NavLink
          to="/sketchbook"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          Sketch Book
        </NavLink>
        <NavLink
          to="/backgroundpainting"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          Background Painting
        </NavLink>
        <NavLink
          to="/characterdesign"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          Character Design
        </NavLink>
        <NavLink
          to="/visualdevelopment"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          Visual Development
        </NavLink>
        <NavLink
          to="/aboutcontact"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          About & Contact
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;
