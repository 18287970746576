import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import SketchBook from "./SketchBook";
import BackgroundPainting from "./BackgroundPainting";
import CharacterDesign from "./CharacterDesign";
import VisualDevelopment from "./VisualDevelopment";
import AboutContact from "./AboutContact";
import Header from "./Header";
import Footer from "./Footer";

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sketchbook" element={<SketchBook />} />
          <Route path="/backgroundpainting" element={<BackgroundPainting />} />
          <Route path="/characterdesign" element={<CharacterDesign />} />
          <Route path="/visualdevelopment" element={<VisualDevelopment />} />
          <Route path="/aboutcontact" element={<AboutContact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
