import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import "./Home.css";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();
const folderPaths = [
  "background-images/",
  "character-images/",
  "sketch-images/",
  "visdev-images/",
];

const Home = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(0); // To force re-render

  useEffect(() => {
    const fetchImages = async () => {
      // Randomly select a folder
      const randomFolderPath =
        folderPaths[Math.floor(Math.random() * folderPaths.length)];
      const params = {
        Bucket: "sia-website",
        Prefix: randomFolderPath,
      };

      const data = await s3.listObjectsV2(params).promise();
      let fetchedImages = data.Contents.filter(
        ({ Key }) => !Key.endsWith("/")
      ).map(({ Key }) => ({
        url: `https://${params.Bucket}.s3.amazonaws.com/${encodeURIComponent(
          Key
        )}`,
        name: Key.split("/").pop(),
        animationClass: `animate-${Math.floor(Math.random() * 7) + 1}`,
      }));

      // Shuffle the images
      fetchedImages = fetchedImages.sort(() => 0.5 - Math.random());
      setImages(fetchedImages);
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setAnimationTrigger((prevTrigger) => prevTrigger + 1); // Update to trigger re-render
    }, 6000); // Every 6 seconds change the image and retrigger animations
    return () => clearInterval(interval);
  }, [images]);

  return (
    <div
      className="viewer"
      style={{
        width: "90%",
        margin: "1vh auto",
        overflow: "hidden",
        borderRadius: "10px",
      }}
    >
      {images.map((image, index) => (
        <div
          key={`${index}-${animationTrigger}`} // Change key to force re-render
          className={`image-slide ${image.animationClass} ${
            index === currentIndex ? "active" : ""
          }`}
          style={{ backgroundImage: `url(${image.url})`, borderRadius: "10px" }} // Keeping borderRadius
        />
      ))}
    </div>
  );
};

export default Home;
