import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";

// Configure AWS SDK
AWS.config.update({
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

const CharacterDesign = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [opacity, setOpacity] = useState(0); // Controls the opacity of the dialog
  const [hoverIndex, setHoverIndex] = useState(null); // Track the index of the hovered thumbnail

  // Define thumbnail dimensions
  const thumbnailWidth = "25vw"; // 25% of the viewport width
  const thumbnailHeight = "30vh"; // 20% of the viewport height

  useEffect(() => {
    const fetchImages = async () => {
      const params = {
        Bucket: "sia-website",
        Prefix: "character-images/",
      };

      s3.listObjectsV2(params, (err, data) => {
        if (err) {
          console.error("Failed to retrieve data from S3", err);
          return;
        }
        const imagesData = data.Contents.filter(
          ({ Key }) => !Key.endsWith("/")
        ).map(({ Key }) => {
          return {
            url: `https://${
              params.Bucket
            }.s3.amazonaws.com/${encodeURIComponent(Key)}`,
            name: Key.split("/").pop(),
          };
        });

        setImages(imagesData);
      });
    };

    fetchImages();
  }, []);

  const openDialog = (image) => {
    setSelectedImage(image);
    setShowDialog(true);
    setTimeout(() => setOpacity(1), 10); // Delay opacity to trigger CSS transition
  };

  const closeDialog = () => {
    setOpacity(0);
    setTimeout(() => setShowDialog(false), 300);
  };

  return (
    <div
      style={{
        width: "90%",
        margin: "0 auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      {images.map((image, index) => (
        <div
          key={index}
          style={{
            width: thumbnailWidth,
            height: thumbnailHeight,
            overflow: "hidden",
            borderRadius: "15px",
            cursor: "pointer",
            boxShadow:
              hoverIndex === index
                ? "0px 0px 20px rgba(0,0,0,0.5)"
                : "0px 0px 10px rgba(0,0,0,0.1)",
            transition: "box-shadow 0.3s ease-in-out",
          }}
          onMouseEnter={() => setHoverIndex(index)}
          onMouseLeave={() => setHoverIndex(null)}
          onClick={() => openDialog(image)}
        >
          <img
            src={image.url}
            alt={image.name}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "15px",
            }}
          />
        </div>
      ))}

      {showDialog && (
        <div
          onClick={closeDialog}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: opacity,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <div
            style={{
              position: "relative",
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "15px",
            }}
          >
            <img
              src={selectedImage.url}
              alt={selectedImage.name}
              style={{
                maxWidth: "90vw",
                maxHeight: "90vh",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CharacterDesign;
